@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

// @import url('https://cdn.jsdelivr.net/npm/@swimlane/ngx-datatable/styles.css');

// themes - our custom or/and out of the box themes
@import 'themes';

// framework component themes (styles tied to theme variables)
@import '@nebular/theme/styles/globals';
@import '@nebular/auth/styles/globals';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/grid';

// loading progress bar theme
@import './pace.theme';

// Loads editor CSS
@import '~simplemde/dist/simplemde.min.css';

@import './layout';
@import './overrides';
@import './global';
// install the framework and custom global styles
@include nb-install() {
  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();
}

input[nbInput],
textarea[nbInput] {
  margin-block: 12px;
}

.datatable-iconCustom::before {
  content: '\66 \65';
}

// adding cellClasses here since
// ngx-datatable uses ViewEncapsulation.None on it's styling
.datatable-cells {
  display: flex !important;
  align-items: center !important;
}

.datatable-align-num {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
  padding-right: 20px !important;
}

nb-user.size-medium .user-name {
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

/* Common styles for selected dropdown, search filter, and search input */
angular2-multiselect div.selected-list > div,
angular2-multiselect div.list-filter > span,
angular2-multiselect div.list-filter > input {
  border-radius: var(--input-rectangle-border-radius);
  background-color: var(--input-basic-background-color);
  border-color: var(--input-basic-border-color);
  color: var(--input-basic-text-color);
  transition: border-color 0.15s ease-in, background-color 0.15s ease-in, color 0.15s ease-in, box-shadow 0.15s ease-in;
  font-size: var(--input-medium-text-font-size);
  font-weight: var(--input-medium-text-font-weight);
  line-height: var(--input-medium-text-line-height);
  padding: 0;
  border-style: var(--input-border-style);
  border-width: var(--input-border-width);
  font-family: var(--input-text-font-family);
  -webkit-appearance: none;
  appearance: none;
}

/* Specific styles for list filter */
angular2-multiselect div.list-filter {
  background-color: var(--input-basic-background-color);
}

/* Common styles for checkboxes and list messages */
angular2-multiselect .pure-checkbox,
angular2-multiselect .list-message {
  font-size: var(--option-medium-text-font-size);
  font-weight: var(--option-medium-text-font-weight);
  line-height: var(--option-medium-text-line-height);
  padding: var(--option-medium-padding);
  background-color: var(--option-background-color);
  color: var(--option-text-color);
  font-family: var(--option-text-font-family);
  transition: background-color 0.15s ease-in, color 0.15s ease-in;

  &:focus {
    background-color: var(--option-focus-background-color) !important;
    color: var(--option-focus-text-color) !important;
    outline: none;

    &.selected {
      background-color: var(--option-selected-focus-background-color);
      color: var(--option-selected-focus-text-color);
    }
  }

  &:hover {
    background-color: var(--option-selected-hover-background-color);
    color: var(--option-hover-text-color);

    &.selected {
      background-color: var(--option-selected-hover-background-color);
      color: var(--option-selected-hover-text-color);
    }
  }

  label {
    color: var(--option-text-color) !important;
  }
}

.dropdown-list ul li:hover {
  background-color: var(--option-selected-hover-background-color) !important;
  color: var(--option-hover-text-color) !important;
}

/* Style for selected item */
angular2-multiselect li.pure-checkbox.selected-item {
  background-color: var(--option-selected-background-color) !important;
  color: var(--option-selected-text-color);
}

nb-card-body > form {
  overflow: visible;
  padding-top: 0;

  > * {
    margin-top: 1rem;
  }
}

.full-name-inputs,
.validation-checkboxes {
  display: flex;
  margin: 1rem -0.5rem 0 !important;

  > * {
    margin: 0 0.5rem;
  }
}

.checkbox-radio {
  display: flex;
}

.demo-items {
  flex: 1 0 33%;
}

.error-message {
  color: red;
}

.menu-sidebar,
.scrollable {
  padding-top: 0 !important;
}

.filter-container {
  width: calc(100% - 48px);
  margin: auto;
  padding: 5px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  input {
    margin-right: 5px;
    border-radius: 6px;
    border: 1px solid gray;
    width: fit-content;
  }
  input::placeholder {
    // padding-left: 10px;
    width: fit-content;
  }
}
.custom-datatable {
  box-sizing: border-box;
  min-height: 300px;
  height: auto;
  width: calc(100% - 48px);
  padding: 0 5px;
  margin: auto;
  box-shadow: none !important;
}

div.empty-row {
  text-align: center !important;
}

.total-count {
  display: flex;
  align-items: center; /* This centers items vertically */
  justify-content: center; /* This centers items horizontally */
}

.text-right {
  text-align: right !important;
}

datatable-body-cell.datatable-body-cell {
  vertical-align: middle !important;
}

div.datatable-body-cell-label {
  vertical-align: middle !important;
}

::ng-deep .datatable-body-cell {
  display: flex !important;
  /* justify-content: center; */
  align-items: center !important;
}

::ng-deep .datatable-body-cell-label {
  width: 100%;
}

ngx-datatable,
datatable-header,
datatable-header-cell,
datatable-body-row,
datatable-body-cell,
datatable-footer,
datatable-pager > ul.pager > li > a {
  background-color: transparent !important;
  color: var(--card-header-text-color) !important;
}

datatable-footer {
  font-size: 14px !important;
  font-weight: 600;
}

datatable-header-cell {
  padding: 0% !important;
}

// datatable-header-cell {
// }

datatable-body-cell {
  box-sizing: border-box !important;
  background-color: transparent !important;
  padding: 8px !important;
}

datatable-body-row {
  background-color: transparent !important;
  :hover {
    datatable-body-cell {
      background-color: transparent !important;
      color: #fff !important;
    }
    // color: var(--card-header-control-text-color) !important;
    background-color: var(--link-text-hover-color) !important;
  }
}

datatable-body-cell {
  :hover {
    background-color: transparent !important;
  }
}

.disabled-input > .select-button > span {
  color: #000 !important;
}

.btn-disabled,
.btn-disabled:hover {
  background-color: #edeff3 !important;
}

// Scrollbar control for the dropdown
.option-list {
  scrollbar-color: var(--color-primary-default) var(--background-basic-color-1) !important;
}

.webCam {
  position: absolute;
  left: 1%;
  bottom: 5%;
  z-index: 10;
}

.custom-option-class {
  // background-color: var(--option-focus-background-color);
  /* color: var(--option-focus-text-color); */
  transition-duration: 0.15s;
  transition-property: background-color, color;
  transition-timing-function: ease-in;
  font-size: var(--option-medium-text-font-size);
  font-weight: var(--option-medium-text-font-weight);
  line-height: var(--option-medium-text-line-height);
  padding: var(--option-medium-padding);
  background-color: var(--option-background-color);
  color: var(--option-text-color);
  font-family: var(--option-text-font-family);
}
